.chat-container-non-secure {
  .str-chat .str-chat__textarea > textarea {
    background-color: #FFE0E0 !important;
  }

  .str-chat .str-chat__send-button > svg > path {
    fill: #CA0000;
  }

  .str-chat .str-chat__fileupload-wrapper {
    display: none;
  }
}

.chat-container-secure {
  .str-chat .str-chat__textarea > textarea {
    background-color: #DAF5E0;
  }

  .str-chat .str-chat__send-button > svg > path {
    fill: #04B02D;
  }
}

.chat-container-schedule {
  .str-chat .str-chat__textarea > textarea {
    background-color: #DAF5E0;
  }
}
.form-m-p{
  padding-bottom: 0 !important;
  // margin: 10px!important;
}

.form-m-p #messagetemplate_id-helper-text{
  display: none;
}

.custom-channel-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-channel > button {
  background-color: #4824ff;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 132%;
  margin-bottom: 24px;
  padding: 6px 24px;
  text-transform: none;
  width: 100%;
}

.search-result-item {
  border-bottom: 1px solid #ddd;
  transition: background-color 0.2s;
}

.search-result-item.focused {
  background-color: #e0e0e0;
}