.hidden {
    display: none;
}

.RaSimpleFormIterator-form-flex-row {
    .RaSimpleFormIterator-form {
        display: flex;
        flex-direction: row!important;
        .ra-input {
            margin-right: 8px;
        }
    }
}

.RaSimpleFormIterator-form {
  max-width: 90%!important;
}

.RaSimpleFormIterator-line {
    border-bottom: none!important;
    max-width: 100%!important;
}