.test-message {
    width: 100%;
    position: absolute;
    left: 0;
    top: 100px;
    @media screen and (min-width: 1650px) {
        top: 0 !important;
        bottom: unset !important;
        left: unset;
        max-width: 500px;
        width: calc(100vw - 1300px);
        right: calc(max(calc(-1* calc(100vw - 1280px)), -540px));
        margin-top: 16px;
    }
    &-form {
        display: flex;
        flex-wrap: wrap;
        padding: 16px 16px 0;
        margin-bottom: 24px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
                    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        > div {
            align-items: center;
        }
        @media screen and (max-width: 1649px) {
            .MuiGrid-container+.MuiGrid-container {
                margin-left: 0;
            }
        }
        @media screen and (min-width: 1650px) {
            flex-direction: column;
            > div {
                width: 100%;
                max-width: 100%;
            }
            button {
                width: calc(100% - 12px);
            }
        }
    }
    h4 {
        text-transform: uppercase;
        color: #50555C;
        margin: 8px 0;
        width: 100%;
        text-align: center;
    }
    button {
        margin-left: 12px;
        margin-bottom: 16px;
    }
}

body:has(.test-message) {
    background-color: #fafafb;
}

.broadcast-create:has(.test-message) {
    @media screen and (min-width: 1650px) {
        position: relative;
        margin-bottom: 20px;
        .broadcast-save-toolbar {
            position: static;
        }
    }
}

.template-create:has(.test-message),
.template-edit:has(.test-message),
.automation-create:has(.test-message),
.automation-edit:has(.test-message),
.provider-notification-create:has(.test-message),
.provider-notification-edit:has(.test-message) {
    position: relative;
    margin-bottom: 20px;

    .test-message {
        top: unset;
        bottom: -200px;
    }
}