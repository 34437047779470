.queryBuilder {
    .ruleGroup {
        border-color: rgba(0, 0, 0, 0.23) !important;
        background: none !important;
        padding: 12px;

        select, input, button {
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5;
            letter-spacing: 0.00938em;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.23);
            min-height: 30px;
            padding: 0 6px;
            margin-top: 0;
            margin-bottom: 0;
        }

        input {
            min-width: 12px;
        }

        button {
            min-width: 30px;
            &.rule-remove {
                padding-bottom: 3px; // center icon
            }
        }

        select.rule-value[multiple] {
            padding-top: 4px;
        }

        .query-multiselect .rule-value { // default value
            min-height: 208px;
        }
        .q-m-small .rule-value {
            min-height: 88px;
        }
        .q-m-5 .rule-value {
            min-height: 108px;
        }
        .q-m-6 .rule-value {
            min-height: 128px;
        }
        .q-m-7 .rule-value {
            min-height: 148px;
        }
        .q-m-8 .rule-value {
            min-height: 168px;
        }
        .q-m-9 .rule-value {
            min-height: 188px;
        }
        .q-m-10 .rule-value {
            min-height: 208px;
        }
    }
}
