.str-chat {
  &.messaging {
    background-color: #FFF;
  }
}
.str-chat__li {
    margin: 0px;
    padding: 15px;
    position: relative;
  
    &:hover {
      background: rgba(0, 0, 0, 0.03);
      .str-chat__message-text-inner {
        background: rgba(0, 0, 0, 0);
      }
    }
  }
  
  .message-wrapper {
    display: flex;

    &--single {
      .str-chat__message-text {
        .str-chat__message-text-inner {
          border-radius: var(--border-radius) var(--border-radius) calc(var(--border-radius-sm) / 2) var(--border-radius);
        }
      }
    }
  
    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }
  
  .message-header {
    display: flex;
    margin-bottom: 5px;
  
    &-name {
      font-family: Helvetica Neue, sans-serif;
      font-weight: 500;
      font-size: 11px;
      line-height: 120%;
      color: #858688;
    }
  
    &-timestamp {
      font-family: Helvetica Neue, sans-serif;
      font-size: 11px;
      line-height: 120%;
      color: #858688;
      margin-top: 5px;
    }
  }

  .schedule-appointment-container {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: 0;
    background-color: #2439F2;
    border-radius: var(--border-radius) var(--border-radius) calc(var(--border-radius-sm) / 2) var(--border-radius);
  }

  .custom-message {
    &__symptom-slider-container {
      .exercise-name {
        color: #777777;
        font-size: 12px;
        line-height: 1.5;
      }
    }
    &__symptom-slider {
      display: flex;
      flex-direction: column;
      padding: 12px;
      margin: 4px 0;
      border: 1px solid #E3E3E3;
      border-radius: 10px;

      // reference: https://blog.hubspot.com/website/html-slider
      .range {
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 255px;
        margin-top: 20px;
          &--title {
            font-weight: 700;
            font-size: 12px;
            line-height: 20px;
          }
          &--label {
            display: flex;
            justify-content: space-between;
            margin-top: 8px;
            width: 255px;
            span {
              font-weight: 500;
              font-size: 12px;
              line-height: 20px;
              color: #757575;
            }
          }
      }
      input[type=range]::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        background: #FFD645;
        background: linear-gradient(90deg, #FFD645 -0.29%, #C92222 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFD645 ', endColorstr='#C92222 ', GradientType=1 );
        height: 4px;
        border-radius: 100px;
      }
      input[type=range]:focus {
        outline: none;
      }
      input[type=range]::-moz-range-track {
        -moz-appearance: none;
        background: #FFD645;
        background: linear-gradient(90deg, #FFD645 -0.29%, #C92222 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFD645 ', endColorstr='#C92222 ', GradientType=1 );
        height: 4px;
        border-radius: 100px;
      }
      input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: 6.5px solid #FFFFFF;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        max-width: 80px;
        position: relative;
        bottom: 8px;
        background-color: #000000;
        -webkit-transition: border 1000ms ease;
        transition: border 1000ms ease;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
      }
      input[type=range]::-moz-range-thumb {
        -moz-appearance: none;
        border: 6.5px solid #FFFFFF;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        max-width: 80px;
        position: relative;
        bottom: 8px;
        background-color: #000000;
        -moz-transition: border 1000ms ease;
        transition: border 1000ms ease;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
      }

    }

    &__schedule-appointment-button {
      display: inline-block;
      padding: 8px 30.5px;
      margin: 4px 15px 10px 15px;
      border: 1px solid #E4E4E4;
      border-radius: 100px;
      background-color: #FFF;
      font-weight: 800;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 1px;
      text-align: center;
    }

    &__appointment-status {
      .status-text {
        font-size: 12px;
        line-height: 20px;
        color: #5a5a5a;
      }
      .appointment-container {
        display: flex;
        flex-direction: column;
        padding: 14px;
        margin: 4px 0;
        min-width: 255px;
        border: 1px solid #E3E3E3;
        border-radius: 10px;
        .header {
          display: flex;
          border-bottom: 1px solid #E3E3E3;
          padding-bottom: 8px;
          margin-bottom: 8px;
          .title-container {
            display: flex;
            flex-direction: column;
          }
          .title {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            .icon {
              display: block;
              margin-left: 10px;
              margin-top: 2px;
              width: 7px;
              height: 7px;
              border-radius: 100%;
              background: orange;
            }
          }
          .scheduled-date {
            font-size: 14px;
            line-height: 20px;
            color: #5a5a5a;
            text-align: left;
          }
        }
        .body {
          display: flex;
          .confirmation-status {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 20px;
            color: #5a5a5a;
            .icon {
              margin-right: 6px;
              margin-top: 4px;
              .checkmark {
                  display:inline-block;
                  width: 16px;
                  height:16px;
                  -ms-transform: rotate(45deg); /* IE 9 */
                  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
                  transform: rotate(45deg);
              }
              
              .checkmark_circle {
                  position: absolute;
                  width:16px;
                  height:16px;
                  background-color: green;
                  border-radius:11px;
                  left:0;
                  top:0;
              }
              
              .checkmark_stem {
                  position: absolute;
                  width:2px;
                  height:7px;
                  background-color:#fff;
                  left:7.5px;
                  top:4px;
              }
              
              .checkmark_kick {
                  position: absolute;
                  width:4px;
                  height:2px;
                  background-color:#fff;
                  left:5px;
                  top:9px;
              }
            }
          }
          .scheduled-time {
            margin-left: 12px;
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 20px;
            color: #5a5a5a;
            .icon {
              margin-top: 4px;
              margin-right: 4px;
            }
          }
        }
      }
    }
  }

.str-chat__message--me {
  & > .str-chat__avatar--circle {
    display: none;
  }
  .custom-message {
    &__symptom-slider {
      align-self: flex-end;
    }
    &__schedule-appointment-button {
      align-self: flex-end;
    }
    &__appointment-status {
      align-self: flex-end;
      .status-text {
        text-align: right;
      }
    }
  }
  .message-header { 
    align-self: flex-end;
  }
  .schedule-appointment-container {
    margin-left: auto;
    margin-right: 0;
  }
  .str-chat__message-text {
    margin-left: auto;
    margin-right: 0;
  }
  .message-header-timestamp {
    margin-left: auto;
    margin-right: 0;
  }
  .str-chat__message-text-inner {
    background-color: #2439F2!important;
    p {
      color: #FFF;
    }
  }
  .str-chat__message-text-inner-secure {
    background-color: #24f22b!important;
    p {
      color: #FFF;
    }
  }
}

.secure-message-wrapper {
  .str-chat__message--me {
    & > .str-chat__avatar--circle {
      display: none;
    }
    .custom-message {
      &__symptom-slider {
        align-self: flex-end;
      }
      &__schedule-appointment-button {
        align-self: flex-end;
      }
      &__appointment-status {
        align-self: flex-end;
        .status-text {
          text-align: right;
        }
      }
    }
    .message-header { 
      align-self: flex-end;
    }
    .schedule-appointment-container {
      margin-left: auto;
      margin-right: 0;
    }
    .str-chat__message-text {
      margin-left: auto;
      margin-right: 0;
    }
    .message-header-timestamp {
      margin-left: auto;
      margin-right: 0;
    }
    .str-chat__message-text-inner {
      background-color: #12A536!important;
      position: relative;
      .str-chat__message-text-inner--secure-icon {
        color: #FFF;
        right: 7px;
        top: 4px;
        position: absolute;
      }
      p {
        color: #FFF;
        padding-right: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
      }
    }
  }
}

.non-secure-message-wrapper {
  .str-chat__message--me {
    & > .str-chat__avatar--circle {
      display: none;
    }
    .custom-message {
      &__symptom-slider {
        align-self: flex-end;
      }
      &__schedule-appointment-button {
        align-self: flex-end;
      }
      &__appointment-status {
        align-self: flex-end;
        .status-text {
          text-align: right;
        }
      }
    }
    .message-header { 
      align-self: flex-end;
    }
    .schedule-appointment-container {
      margin-left: auto;
      margin-right: 0;
    }
    .str-chat__message-text {
      margin-left: auto;
      margin-right: 0;
    }
    .message-header-timestamp {
      margin-left: auto;
      margin-right: 0;
    }
    .str-chat__message-text-inner {
      background-color: #FFF0F0!important;
      position: relative;
      .str-chat__message-text-inner--non-secure-icon {
        color: #CA0000;
        right: 7px;
        top: 4px;
        position: absolute;
      }
      p {
        color: #000;
        padding-right: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
      }
    }
  }
}

.patient-text-wrapper {
  .str-chat__message--me {
    & > .str-chat__avatar--circle {
      display: none;
    }
    .custom-message {
      &__symptom-slider {
        align-self: flex-end;
      }
      &__schedule-appointment-button {
        align-self: flex-end;
      }
      &__appointment-status {
        align-self: flex-end;
        .status-text {
          text-align: right;
        }
      }
    }
    .message-header { 
      align-self: flex-end;
    }
    .schedule-appointment-container {
      margin-left: auto;
      margin-right: 0;
    }
    .str-chat__message-text {
      margin-left: auto;
      margin-right: 0;
    }
    .message-header-timestamp {
      margin-left: auto;
      margin-right: 0;
    }
    .str-chat__message-text-inner {
      background-color: #E8EAEF!important;
      p {
        color: #FFF;
        padding-right: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
      }
    }
  }
}
  
  .str-chat__li:hover .str-chat__message-simple__actions {
    position: absolute;
    top: -14px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    border-radius: 100px;
    background: white;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);
    z-index: 1;
    padding: 0 4px 0;
  
    &__action--reactions {
      display: flex;
    }
  
    &__action--options {
      display: flex;
    }
  
    &__action--thread {
      display: flex;
    }
  }
  
  .str-chat__message-simple__actions {
    margin-top: 0;
  }
  
  .str-chat__message-actions-box {
    top: initial;
    left: -100px;
    border-radius: 16px 16px 0 16px;
  }
  
  .str-chat__message-text {
    display: flex;
  }
  
  .str-chat__message-text-inner {
    position: relative;
    flex: 1;
    display: block;
    padding: 0.5em 1em;
    min-height: 32px;
    font-size: 15px;
    color: black;
    border-radius: 0;
    border: 0px;
    margin-left: 0;
    background-color: #EBEDF1!important;

    .str-chat__message-text-inner--attachment {
      padding-right: 10px;
    }
  
    p {
      font-family: Helvetica Neue, sans-serif;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      margin-bottom: 0;
      margin-top: 0px;
    }
  }
  
  .str-chat__message-replies-count-button {
    font-family: Helvetica Neue, sans-serif;
    font-weight: bold;
    font-size: 13px;
    text-align: right;
    color: #4e1d9d;
  }
  
  .str-chat__simple-reactions-list {
    border-radius: 10px;
  }
  
  .str-chat__reaction-selector {
    max-height: 40px;
    top: -58px;
    right: 20px;
    border-radius: 20px;
    background: white;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
    border: 1px solid #bed5e4;
  }
  
  .str-chat__message-reactions-list-item__count {
    color: #000000;
  }
  
  .str-chat__message-simple-status {
    right: 22px;
  }
  
  .quoted-message-inner {
    margin-top: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }
  
  .str-chat__li .quoted-message.mine .quoted-message-inner {
    background: #ebebeb;
  }

  .str-chat__send-button {
    display: block;
  }

  .str-chat__input-flat .str-chat__textarea>textarea {
    padding: 17px 24px;
    background: #FFF;
    border-radius: 50px!important;
    border: 1px solid #A3A3A3!important;
    box-shadow: none!important;
  }