.support-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 100%;
  right: 100px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-top: none;
  width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9;
  overflow-y: auto;
  max-height: 275px;
  padding: 5px;
}

.support-menu-item {
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
}

.support-menu-link {
  text-decoration: none;
  color: black;
}

.app-bar-container {
  position: relative;
}
