.billings {
    textarea {
        width: 100%;
        font-family: sans-serif;
        font-size: 16px;
    }
    .c-grey {
        color: #757575;
    }
    .legend-container {
        display: flex;
        justify-content: center;
    }
    .legend-marker {
        display: flex;
        align-items: center;
        margin: 1em 1em 0 1em;
    }
    .legend-color {
        width: 16px;
        height: 4px;
        margin-right: 0.5em;
        border-radius: 8px;
        &.green {
            background-color: #11BF95;
        }
        &.yellow {
            background-color: #FFD645;
        }
        &.red {
            background-color: #EB1A41;
        }
    }
    .diagnosis-chip {
        display: inline-block;
        padding: 8px 20px;
        background-color: #E3E3E3;
        border-radius: 16px;
    }
    .activity-log {
        .billable, .unbillable {
            width: 8px;
            min-height: 52px;
            margin: -6px 0 -6px -16px;
        }
        .billable {
            background-color: green;
        }
    }
    .activity-log-drawer {
        padding: 1em;
        .save {
            padding: 8px 16px;
            width: 90px;
            height: 38px;
            background: #2439F2;
            border-radius: 6px;
            color: #FFF;
        }
    }
}