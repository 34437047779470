.channel-preview__container {
    height: 84px;
    padding: 10px 0;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 20px;
}

.channel-preview__container:hover {
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07);
    transition: background 0.1s ease-in-out;
}

.channel-preview__container.selected {
    background: #5331FF;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07);
    transition: background 0.1s ease-in-out;
   color: #ffffff;
}

.channel-preview__content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 4px 8px;
    width: 100%;
}

.channel-preview__content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    height: 18px;
    margin-bottom: 4px;
}

.channel-preview__content-name {
    font-family: Helvetica Neue, sans-serif;
    font-weight: 500;
    font-size: 15px;
    margin: 0;
    max-width: 158px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.channel-preview__content-message {
    font-family: Helvetica Neue, sans-serif;
    font-size: 13px;
    line-height: 16px;
    margin: 0;
    height: 16px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.message-divider {
    height: 1px;
    width: 50px;
    background: #E0E0E0;
}



// List Container styles

.str-chat {
    max-height: calc(100vh - 3em);
}

.str-chat.str-chat-channel-list.messaging {
    margin: 1em 0.5em;
    border-radius: var(--border-radius-md);
    overflow-y: scroll;
    max-height: calc(100vh - 5em);
    &.messaging {
        background-color: #FFFFFF;
    }
    .str-chat__channel-search {
        background-color: #FFFFFF;
        border-radius: var(--border-radius-md);
        .add-channel > button {
            padding: 6px 14px;
            background-color: #4824FF;
            color: #FFF;
            border-radius: 8px;
            text-transform: none;
            font-weight: 700;
            font-size: 18px;
            line-height: 132%;
            margin-bottom: 24px;
            width: 100%;
        }
    }
    .str-chat__channel-list-messenger {
        background-color: #FFFFFF;
    }
    .message-divider {
        width: calc(100% - 110px);
        margin-right: 10px;
        margin-left: auto;
    }
}

.str-chat-channel.messaging .str-chat__main-panel {
    padding-bottom: 1em;
    padding-right: 0.5em;
    .t-grey {
        color: #7D8086;
    }
    .custom-header {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 4px 18px rgb(0 0 0 / 20%);
        z-index: 1;
        padding: 1em;
        background: #FFFFFF;
        border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
        .top-info-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1em;
        }
        .schedule-appointment {
            display: flex;
            justify-content: flex-end;
            width: 50%;
            button {
                padding: 10px 14px;
                background-color: #4824FF;
                color: #FFF;
                border-radius: 8px;
                text-transform: none;
                font-weight: 500;
                font-size: 14.22px;
                line-height: 112%;
                .plus {
                    margin-right: 0.5em;
                    --b: 2px; /* the thickness */
                    width: 18px; /* the size */
                    aspect-ratio: 1;
                    border: 4px solid #FFF; /* the outer space */
                    background:
                        conic-gradient(from 90deg at var(--b) var(--b),#FFF 90deg,#4824FF 0) 
                        calc(100% + var(--b)/2) calc(100% + var(--b)/2)/
                        calc(50%  + var(--b))   calc(50%  + var(--b));
                    display: inline-block;
                    border-radius: 50%;
                }
                .minus {
                    position: relative;
                    margin-right: 0.5em;
                    width: 18px;
                    height: 18px;
                    background-color: #FFF;
                    display: inline-block;
                    border-radius: 50%;
                    &::after{
                        content: "";
                        position: absolute;
                        display: inline-block;
                        top: calc(50% - 1px);
                        left: 4px;
                        width: 10px;
                        height: 2px;
                        background-color: #4824FF;
                    }
                }
            }
        }
        .checkboxes-container {
            display: flex;
            align-items: center;
            .t-grey {
                margin-right: 0.5em;
            }
            .MuiFormGroup-root {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-left: 10px;
                .MuiFormControlLabel-root {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-right: 12px;
                    margin-bottom: 6px;
                    margin-left: -5px;
                    background: #E3E3E3;
                    border-radius: 16px;
                }
                .checkbox {
                    padding: 0px 10px;
                    margin: 0.5em 0.5em 0.5em 0;
                    background: #E3E3E3;
                    border-radius: 16px;
                    .Mui-checked {
                        color: #505765;
                    }
                    &.checked {
                        background: rgba(52, 199, 89, 0.16);
                    }
                }
            }
        }
    }
    .str-chat__input-flat {
        border-radius: var(--border-radius-md);
        background-color: #f3f3f3;
        padding-left: 0;
    }
    .custom-message-input-container {
        border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
        background-color: #f3f3f3;
        padding-left: 1em;
        padding-top: 1em;

        @media screen and (max-width: 1700px) {
            > div:first-child {
                display: flex;
                flex-direction: column-reverse;
                align-items: baseline;
                >div {
                    width: 100%;
                    max-width: unset;
                    &:last-child {
                        margin-bottom: 1em;
                    }
                }
            }
        }

        .MuiStack-root {
            display: flex;
            flex-direction: row;
            justify-content: end;
            align-items: end;
            width: 100%;
            .MuiAutocomplete-root {
                max-width: 500px;
            }
            >button {
                height: 38px;
                margin-bottom: 4px;
                margin-left: 20px;
                font-weight: bold;
            }
        }
    }
    .schedule-appointment-tag {
        display: inline-block;
        padding: 10px;
        background: #FFFFFF;
        border: 1px solid #4824FF;
        border-radius: 8px;
    }
}