.dashboard {
    .appointment-pros-list {
        .RaList-main {
            margin-top: 20px;

            .RaList-actions {
                height: 0;

                .add-filter {
                    display: none;
                }
            }
        }
    }

    .key-open-tasks {
      .RaList-main {
          margin-top: 20px;

          .RaList-actions {
              height: 0;

              .add-filter {
                  display: none;
              }
          }
      }
  }
}