.condition-list {
  width: 100%;
  z-index: 3;

  .MuiAccordion-root::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #ddd;
  }
  
  .MuiAccordionSummary-root::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #ddd;
  }

  .MuiAccordion-root::before {
    opacity: 1 !important;
    background-color: #ddd;
  }

  .MuiAccordionSummary-root {
    min-height: 56px !important;
    padding-right: 0px;
  }

  .MuiAccordionSummary-content {
    margin: 12px 0 !important;
  }

  .condition-list-accordion {
    margin: 0 !important;
    box-shadow: none;

    .condition-list-item-container {

      .condition-list-item {
        height: 32px;

        .condition-approval-status-select {
          width: 50%;
          padding: 0px;
          border: none;
          font-size: 14px;
          line-height: 1.5;
    
          & .MuiSelect-select {
            padding: 8px;
          }
        
          &.pending {
            background-color: #ffffaa;
          }

          &.approved {
            background-color: #d8ffd8;
          }

          &.denied {
            background-color: #ffdddd;
          }
        }
      }
    }
  }

  .condition-list-header-container {
    .condition-list-header {
      height: 32px;
    }
  }

  .exercise-list-container {
    padding: 0 0;

    .exercise-list {
      table-layout: fixed;
      width: 100%;

      .exercise-list-cell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .exercise-list-cell-icon {
          width: 20px;
          height: 20px;
        }
      }
      
      .exercise-list-header {
        height: 56px;
        .MuiTableCell-root {
          font-weight: bold;
        }

        .exercise-list-header-row {
          height: 56px;
        }

        .exercise-list-header-checkbox {
          width: 14px;
          height: 14px;
          cursor: pointer;
        }
      }

      .exercise-list-item {
        height: 56px;

        &.exercise-inactive {
          background-color: #f4f4f4;
        }

        .exercise-list-item-checkbox {
          width: 14px;
          height: 14px;
          cursor: pointer;
        }
      }

      .exercise-list-key-text {
        font-weight: bold;
        opacity: 0.5;
      }

      .exercise-increased-symptoms {
        color: red;
      }

      .exercise-too-hard {
        //color: red;
        font-weight: bolder;
        font-style: italic;
      }

      .exercise-completed {
        color: green;
      }

      .exercise-up-next {
        color: blue;
        //font-weight: bold;
      }

      .exercise-skipped {
        //color: blue;
        //font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}