.secure-message-toggle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  .secure-message-toggle-tooltip-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .secure-message-toggle-container-secure {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: left;
    padding-left: 10px;

    .secure-message-toggle-icon-container {

    }

    .secure-message-toggle-text-container {
      color: #04A02D;
      font-size: small;
      font-weight: bold;
    }

    .secure-message-toggle-text-button-container {
      display: flex;
      flex-direction: row;

      .secure-message-toggle-text-button {
        color: #CA0000;
        font-size: smaller;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .secure-message-toggle-container-non-secure {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: left;
    padding-left: 10px;

    .secure-message-toggle-icon-container {

    }

    .secure-message-toggle-text-container {
      color: #CA0000;
      font-size: small;
      font-weight: bold;
    }

    .secure-message-toggle-text-button-container {
      display: flex;
      flex-direction: row;
      
      .secure-message-toggle-text-button {
        color: #04A02D;
        font-size: smaller;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .secure-message-toggle-container-schedule {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: left;
    padding-left: 10px;

    .secure-message-toggle-icon-container {

    }

    .secure-message-toggle-text-container {
      color: #04A02D;
      font-size: small;
      font-weight: bold;
    }

    .secure-message-toggle-text-button-container {
      display: flex;
      flex-direction: row;

      .secure-message-toggle-text-button {
        color: #CA0000;
        font-size: smaller;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}