.toolbar-dynamic-text {
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: rgba(0, 0, 0, 0.87);
    border: none;
    height: 34px;
    padding: 4px;
    width: 200px; // make it consistent for emoji picker position
    &:hover {
        background: rgba(0, 0, 0, 0.04);
        cursor: pointer;
    }
}