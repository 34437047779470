.toolbar-emoji-container {
    position: relative;
    &-button {
        padding: 4px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        background: #fff;
        height: 34px;
        color: rgba(0, 0, 0, 0.54);
        svg circle {
            &:nth-child(3), &:nth-child(4) { // each eye renders twice
                fill: transparent;
            }
        }
        &:hover {
            background: rgba(0, 0, 0, 0.04);
            cursor: pointer;
        }
        &.active {
            color: rgba(0, 0, 0, 0.87);
            background: rgba(0, 0, 0, 0.08);
        }
    }
    // &.rich-toolbar:not(.sidebar-open) aside { // sidebar is 190px smaller when closed
    //     right: 0;
    //     @media screen and (min-width: 758px) {
    //         @media screen and (max-width: 1083px) {
    //             left: 0;
    //         }
    //     }
    // }
    // &.rich-toolbar.sidebar-open aside {
    //     right: 0;
    //     @media screen and (min-width: 948px) {
    //         @media screen and (max-width: 1273px) {
    //             left: 0;
    //         }
    //     }
    // }
    &.rich-toolbar aside {
        right: 0;
    }
    &.simple-toolbar aside {
        right: -158px; // center over selector
    }
    aside {
        position: absolute;
        z-index: 10;
        bottom: 45px;
    }
}

// align emoji pop-up based on container size
.template-form, .broadcast {
    container-type: inline-size;
}
@container (max-width: 985px) {
    .toolbar-emoji-container.rich-toolbar aside {
        left: 0;
    }
}