.exerciselibrarys {
    .diagnosis-chip {
        display: inline-block;
        padding: 8px 10px;
        background-color: #E3E3E3;
        border-radius: 16px;
        font-size: 14px;
        font-weight: 600;
        margin-right: 20px;
        text-align: center;
    }
}