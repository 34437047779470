.sidebar-menu {
    display: flex;
    flex-direction: column;
    height: calc(100% - 16px);
    .settings {
        display: flex;
        flex-direction: column;
        margin-left: 0em;
        margin-top: auto;
        margin-bottom: 2em;
        a {
            text-decoration: none;
            color: #000;
            font-weight: 500;
            margin-left: 0.5em;
        }
        .MuiSvgIcon-root {
            color: rgba(0, 0, 0, 0.6);
        }
        .MuiBox-root {
            vertical-align: middle;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 400;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            color: #888, important !important;
        }
    }
}

#provider-selector {
    margin: auto;
    svg {
        color: black;
    }
    .MuiSelect-select {
        padding-top: 6px;
        padding-bottom: 6px;
        font-weight: 900;
    }
    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
}